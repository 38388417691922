<template>
  <div>
    <b-navbar toggleable="lg" type="default" class="p-0 pb-lg-0 fixed-top">
      <b-container>
        <b-navbar-toggle class="w-100 p-0" target="nav-collapse">
          <div style="position: absolute; top: 25px; left: 10px; padding: 10px" @click="() => { $router.go(-1)}" v-if="$route.name === 'DeviceDetails' || $route.name === 'MessageHistory'">
            <font-awesome-icon icon="arrow-circle-left" class="text-muted"></font-awesome-icon>
          </div>

          <img
            :src="require('@/assets/images/vsrs.png')"
            alt=""
            style="width: 150px"
            class=""
          />

          <div style="position: absolute; top: 25px; right: 10px; padding: 10px" @click="() => { $router.push({name: 'Login'}) }" v-if="getUsersData">
            <font-awesome-icon icon="sign-out-alt" class="text-muted"></font-awesome-icon>
          </div>
        </b-navbar-toggle>

        <b-collapse
          id="nav-collapse"
          is-nav
          v-model="isCollapseOpen"
          class="pb-md-4 pb-lg-0"
        >
          <b-navbar-nav class="text-uppercase">
            <li class="nav-item d-none d-lg-block">
              <router-link :to="{ name: 'Home' }" active-class="active" exact>
                <img
                  :src="require('@/assets/images/vsrs.png')"
                  alt=""
                  style="width: 140px; padding-top: 0px"
                  class=""
                />
              </router-link>
            </li>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>
<script>
export default {
  name: "Header",
  components: {},
  data() {
    return {
      isCollapseOpen: false,
    };
  },
  computed: {
    getUsersData () {
      return this.$store.getters.getUser
    }
  }
};
</script>
