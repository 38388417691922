<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <Header />
    <div class="contentContainer">
      <transition :name="transitionName">
        <router-view :key="$route.fullPath" />
      </transition>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header";

const DEFAULT_TRANSITION = "show";
export default {
  name: "Home",
  components: {
    Header
  },
  data() {
    return {
      prevHeight: 0,
      transitionName: DEFAULT_TRANSITION
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;

      console.log("transitionName", transitionName);

      if (transitionName === "show") {
        const toDepth = to.path.split("/").length;
        const fromDepth = from.path.split("/").length;
        transitionName = toDepth < fromDepth ? "showRight" : "show";
        console.log("transitionName", transitionName);

      }

      this.transitionName = transitionName || DEFAULT_TRANSITION;

      next();
    });
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
  }
};
</script>
<style lang="scss">
//#app {
//  font-family: Avenir, Helvetica, Arial, sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  text-align: center;
//  color: #2c3e50;
//}
//
//#nav {
//  padding: 30px;
//
//  a {
//    font-weight: bold;
//    color: #2c3e50;
//
//    &.router-link-exact-active {
//      color: #42b983;
//    }
//  }
//}
</style>
