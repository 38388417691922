import Vue from "vue";
import VueRouter from "vue-router";
import List from "../views/List";
import QrCodeScanner from "@/views/QrCodeScanner";
// import store from '../store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/qr-code-scanner",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "../views/Login"),
    meta: {
      transitionName: 'show'
    },
  },
  {
    path: "/qr-code-scanner",
    name: "QrCodeScanner",
    component: QrCodeScanner,
    meta: {
      requiresAuth: false,
      transitionName: 'show'
    },
  },
  {
    path: "/list",
    name: "List",
    component: List,
    meta: {
      requiresAuth: true,
      transitionName: 'show'
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   console.log("store", store, to.matched.some(record => record.meta.requiresAuth));
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     console.log("here!");
//     if (store.getters.getUser || JSON.parse(localStorage.getItem('userInfo'))) {
//       next();
//     } else {
//       console.warn("Za dostop do strani morate biti prijavljeni!");
//       next({name: 'Login'})
//     }
//   } else {
//     next();
//   }
// });

export default router;
