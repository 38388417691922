<template>
  <div class="listPage">
    <b-container class="">
      <b-row class="row">
        <b-col md="12" class="my-3">
          <h5>List of devices</h5>
        </b-col>
      </b-row>
      <b-row class="row">
        <b-col md="12" class="overflow-hidden">
          <div class="fullWidthOnMobile">
            <b-list-group v-if="machines">

              <b-list-group-item href="#" class="flex-column align-items-start" v-for="(machine, index) in machines" :key="'machine_' + index" @click.stop="onDeviceClick(machine.uuid)">
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">
                    <font-awesome-icon icon="circle" :class="getMachineStatus(machine.modified)"></font-awesome-icon>
                    {{machine.name}}
                  </h5>
                  <small>
                    <timeago :datetime="$moment(machine.modified).local()" v-if="machine.modified"></timeago>
                    <span v-if="!machine.modified">n/a</span>
                  </small>
                </div>

                <small>{{machine.version}}</small>
              </b-list-group-item>

            </b-list-group>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>


export default {
  name: "List",
  components: {},
  data() {
    return {
      error: "",
      machines: []
    };
  },
  mounted() {
    const _this = this;
    _this.getMachines(true);

    _this.$setInterval(() => {
      _this.getMachines();
    }, 5 * 1000);
  },
  destroyed() {
    window.clearTimeout(this.timer);
  },
  computed: {

  },
  methods: {
    getMachines(init) {
      const _this = this;

      _this.isLoading = true;

      _this._axios.get(_this.$config.api.baseUrl + _this.$config.api.machines)
          .then((res) => {
            _this.machines = res.data.data;
            _this.isLoading = false;
            if(!init) {
              _this.$toast.success('Data updated', {
                position: 'bottom'
              });
            }

          })
          .catch((error) => {
            console.error(error);
            _this.isLoading = false;
          });
    },

    getMachineStatus(modified) {
      const _this = this;

      if(modified === null || _this.$moment(modified).local().isBefore(_this.$moment().subtract(5, 'minutes'))) {
        return 'text-muted'
      } else {
        return 'text-success'
      }
    },

    onDeviceClick(deviceId) {
      const _this = this;
      _this.$router.push({name: 'DeviceDetails', params: {deviceId: deviceId}});
    },
  },
};
</script>
