import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/fontawesome";
import "./plugins/axios";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@/assets/styles/midalix-mobile.scss";
import axios from 'axios';
import moment from 'moment';
import Timer from 'vue-plugin-timer';
import VueTimeago from 'vue-timeago'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueCryptojs from 'vue-cryptojs';

Vue.config.productionTip = false;

// global var in config/config.js
Vue.prototype.$config = window._config;

// axios
Vue.prototype._axios = axios;

// moment
Vue.prototype.$moment = moment;

// timer
Vue.use(Timer);

// toast
Vue.use(VueToast);

// crypto
Vue.use(VueCryptojs);

// timeago
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  locales: {

  }
})

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).token : null;

    if (token) {
      config.headers.common["Authorization"] = token;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {

  if (error.response && (error.response.status === 401 || error.response.status === 405)) {
    console.error("Session not valid!");

    console.error("Error:", error.response.data.message);

  } else if(error.response.status === 403) {
    Vue.$toast.open({
      message: 'Session expired!',
      type: 'error',
      position: 'bottom',
    });

    router.push({name: 'Login'}).catch(() => {});
  } else if(error.response.status >= 500) {
    console.log("VueToast.", Vue.$toast);

    Vue.$toast.open({
      message: 'Server error!',
      type: 'error',
      position: 'bottom',
    });
  }

  // Do something with response error
  return Promise.reject(error);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
