import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
  },
  getters: {
    getUser: state => state.user ? state.user : JSON.parse(localStorage.getItem('userInfo')),
  },
  mutations: {
    SET_USER(state, payload) {
      Vue.set(state, "user", payload);
    },
  },
  actions: {
    updateUser(context, data) {
      context.commit("SET_USER", data);
    },
  },
  modules: {},
});
